<template>
  <div class="home-container">
    <div class="header">
      <router-link class="header-link" to="/profile"><h1 class="header-title">Sabiba</h1></router-link>
      <div class="main-nav">
        <router-link to="/news"><button class="nav-button">News</button></router-link>
        <router-link to="/merch"><button class="nav-button">Merch</button></router-link>
        <router-link to="/games"><button class="nav-button">Games</button></router-link>
      </div>
    </div>
    <div class="body">
      <h2>Sabiba News is Coming Soon!</h2>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Updates',
  components: {}
}
</script>

<style scoped>
  .home-container {
    height: 100%;
    width: 100%;
  }
  .home-logo {
    width: 300px;
  }
  .main-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .nav-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin: 0px 24px;
    padding: 12px;
  }
  .nav-button:hover {
    background-color: #F86F15;
    color: #fff;
  }
  .header {
    border-bottom: 1px solid #000;
  }
  .header-title {
    color: #F86F15;
  }
  .header-title:hover {
    color: #000;
  }
  .header-link {
    text-decoration: none;
  }
  .body {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 80px 20px 40px;
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    .home-logo {
      width: 85%;
    }
  }
  @media screen and (min-width: 1180px) {
    .home-logo {
      width: 75%;
    }
  }

</style>
