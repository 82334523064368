<template>
  <div class="main-container">
    <MainNav />
    <div class="body">
      <h2 class="news-title">Please continue to visit our website for updates and seasonal changes</h2>
      <div class="daily-announcement">7.24.23 - We are open for business... hope to see you soon!
      </div>
      <div class="daily-announcement">6.18.23 - Thanks to everyone who came out yesterday! All of you guys have made this a perfect opening weekend! I want to say thank you to my dad
        for all of his support and help in getting Sabiba off the ground! Happy Father's Day!
      </div>
      <div class="daily-announcement">6.17.23 - When the sun is out and the rain is not falling, stop by and get some refreshing lemonade... 
        and a chocolate chip cookie or 2! Hope to see you today guys!</div>
      <div class="daily-announcement">6.16.23 - Today we celebrated what was our first soft opening! Thank you to all who showed up and celebrated with us!
        Your support means a lot... we will see you tomorrow!</div>
      <div class="announcement">Thank you for visiting Sabiba. We are currently serving our Mountain Brook Famous Lemonade at our current location on select days.
        We will be indicating those days via this site and/or in person... so please feel free to ask us what days when you are visiting!
      </div>
      <img alt="logo" class="sign-logo" src="@/assets/sabiba-sign.png">
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainNav from '@/components/mainNav.vue'

export default {
  name: 'News',
  components: {
    MainNav,
  }
}
</script>

<style>
  .main-container {
    height: 100%;
    width: 100%;
  }
  .body {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .news-title {
    color: #ff00a9;
    margin: 60px auto 0px;
  }
  .daily-announcement {
    font-size: 24px;
    margin: 40px 0px;
    width: 75%;
  }
  .announcement {
    font-size: 18px;
    margin: 80px 20px 40px;
    width: 65%;
  }
  .sign-logo {
    padding: 20px;
    width: 90%;
  }
  .footer {
    height: 200px;
    width: 100%;
  }

</style>