<template>
  <div class="header">
    <div class="title-row">
      <img class="small-logo" src="@/assets/sabiba-logo.png" />
      <router-link class="header-link" to="/"><h1 class="header-title">Sabiba</h1></router-link>
      <img class="small-logo" src="@/assets/sabiba-logo.png" />
    </div>
    <div class="main-nav">
      <router-link to="/news" class="nav-link" exact><button class="nav-button">News</button></router-link>
      <router-link to="/merch" class="nav-link" exact><button class="nav-button">Merch</button></router-link>
      <router-link to="/games" class="nav-link" exact><button class="nav-button">Games</button></router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main-nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .title-row {
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
  }
  .small-logo {
    height: 30px;
  }
  .router-link-exact-active {
    background-color:#F86F15;
    color: #fff!important;
  }
  .router-link-exact-active:hover {
    background-color: #a4de02;
    color: #ff00a9!important;
  }
  .nav-link {
    align-items: center;
    color: #000;
    display: flex;
    justify-content: center;
    margin: 0px;
    text-decoration: none;
    width: 160px;
  }
  .nav-link:hover {
    background-color: #a4de02;
    color: #ff00a9;
  }
  .nav-button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-size: 18px;
    margin: 0px;
    padding: 12px;
  }
  .header {
    border-bottom: 1px solid #000;
  }
  .header-title {
    color: #F86F15;
    margin: 8px 12px;
  }
  .header-title:hover {
    color: #000;
  }
  .header-link {
    text-decoration: none;
  }
</style>
