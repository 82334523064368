<template>
  <div class="home-container">
    <router-link to="/news"><img alt="logo" class="home-logo" src="@/assets/sabiba-logo.png"></router-link>
    <h1 class="site-action">Click The Cow</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {}
}
</script>

<style scoped>
  .home-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  .home-logo {
    width: 300px;
  }
  .site-action {
    color: #a4de02;
  }
  @media screen and (min-width: 768px) {
    .home-logo {
      width: 50%;
    }
  }
  @media screen and (min-width: 1180px) {
    .home-logo {
      width: 40%;
    }
  }

</style>
