<template>
  <div class="home-container">
    <MainNav />
    <div class="merch-body">
      <h2 class="title">Sabiba Merch is Coming Soon!</h2>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MainNav from '@/components/mainNav.vue'

export default {
  name: 'Home',
  components: {
    MainNav,
  }
}
</script>

<style scoped>
  .home-container {
    height: 100%;
    width: 100%;
  }
  .home-logo {
    width: 300px;
  }
  .merch-body {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 80px 20px 40px;
  }
  .title {
    width: 90%;
  }
  @media screen and (min-width: 768px) {
    .home-logo {
      width: 85%;
    }
  }
  @media screen and (min-width: 1180px) {
    .home-logo {
      width: 75%;
    }
  }

</style>
